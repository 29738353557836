import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setItems } from "../reduxstore/itemsSlice";
import { setSelectedItem } from "../reduxstore/selectedItemSlice";
import { initializeItemsStatus } from "../reduxstore/itemsStatusSlice";
import { useLocation } from "react-router-dom";
import {
  Box,
  Tabs,
  Tab,
  Paper,
  Stack,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { api } from "../store";
import "react-quill/dist/quill.snow.css";
import "./Editor.scss";
import "./RFPEditor.scss";
import { styled } from "@mui/material/styles";
import RFPChecklist from "../Components/rfp/RFPChecklist";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import RFPChecklistSkeleton from "../Components/rfp/RFPCheckListSkeleton";
import TipTapEditor from "../Components/TipTapEditor";
import DraftLeftPanelComponent from "../Components/rfp/EditableDraftPanelLeft";
import PDFViewer from "../Components/rfp/PDFViewer";
import DraftSourcesPanelComponent from "../Components/rfp/DraftSourcesPanel";

function RFPEditor() {
  const { state, search } = useLocation();
  const [userArchiveIDs, setUserArchiveIDs] = useState([]);
  const [tab, setTab] = React.useState("overview");
  const [rfpCheckListData, setRFPCheckListData] = useState(null);
  const [rowData, setRows] = useState([]);
  const [projectFiles, setProjectFiles] = useState([]);
  const [isCheckListLoading, setIsChecklistLoading] = useState(true);
  const [activeDocument, setActiveDocument] = useState(null);
  const [docs, setDocs] = useState([]);
  const rteRef = useRef(null);

  const [rfpData, setRfpData] = useState(null);
  const dispatch = useDispatch();

  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };

  const queryParams = getQueryParams(search);
  const projectId = queryParams.get("projectId") || state?.projectId;
  const projectName = queryParams.get("projectName") || state?.projectName;
  const [lastPage, setLastPage] = useState(0);
  const pdfViewerRef = useRef(null);

  const [projectNameEditing, setProjectNameEditing] = useState(false);
  const [editedProjectName, setEditedProjectName] = useState(projectName);

  const countTodos = (text) => {
    const regex = /\[TODO:[^\[]*?\]/g;
    const matches = text.match(regex);
    return matches ? matches.length : 0;
  };

  const handleProjectNameChange = (event) => {
    setEditedProjectName(event.target.value);
  };

  const handleProjectNameSubmit = async () => {
    if (editedProjectName.trim() !== projectName) {
      try {
        await api.put("/project/updateProjectName", {
          projectId,
          projectName: editedProjectName.trim(),
        });
        // Update the URL with the new project name
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.set("projectName", editedProjectName.trim());
        window.history.replaceState({}, "", newUrl.toString());
      } catch (error) {
        console.error("Error updating project name:", error);
        // Revert to the original name if there's an error
        setEditedProjectName(projectName);
      }
    }
    setProjectNameEditing(false);
  };

  const fetchProjectData = async () => {
    try {
      const response = await api.get("/project/getProjectById", {
        params: { projectId },
      });
      if (response.data?.project.rfx_checklist) {
        setRFPCheckListData(response.data.project.rfx_checklist);
      }
      setIsChecklistLoading(false);

      if (response.data?.project.rfx_response_sections) {
        const filteredItems =
          response.data.project.rfx_response_sections.filter(
            (item) => item.type === "Free Text" && item.answer != null
          );

        dispatch(setItems(filteredItems));

        dispatch(
          initializeItemsStatus(
            filteredItems.map((item) => ({
              uuid: item.uuid,
              todoCount: countTodos(item.answer || 0),
            }))
          )
        );
        if (filteredItems.length > 0) {
          dispatch(
            setSelectedItem({
              uuid: filteredItems[0].uuid,
              section_title: filteredItems[0].section_title,
              answer: filteredItems[0].answer,
              sources: filteredItems[0].sources,
              requirements: filteredItems[0].requirements,
            })
          );
        }
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    const fetchProjectFiles = async () => {
      try {
        const fileList = await api.get("file/getFiles", {
          params: { projectId: projectId },
          headers: { "Cache-Control": "no-cache" },
        });
        const files = [fileList.data[0]];
        setProjectFiles(files);
        const docs = await Promise.all(
          files.map(async (file) => {
            const signedResponse = await api.get(
              `file/getSignedURL/${file.id}`
            );
            const metadataResponse = await api.post(
              "/file/getMetadataForFile",
              { fileId: file.id }
            );

            return {
              uri: signedResponse.data.url,
              metadata: metadataResponse.data,
            };
          })
        );

        setDocs(docs);

        if (docs.length > 0) {
          setActiveDocument(docs[0]);
        }
      } catch (err) {
        console.log(err);
        console.log(err?.response?.data?.message);
      }
    };

    // Fetch project data once on mount
    fetchProjectData();
    fetchProjectFiles();
  }, [projectId]);

  const getUserArchivesId = async () => {
    const response = await api.get("/archives/getUserArchivesId");
    if (response.data.archives.length > 0) {
      setUserArchiveIDs(response.data.archives);
    } else setUserArchiveIDs([]);
    getArchives(response.data.archives);
  };

  const getArchives = async (archiveIds) => {
    const idsString = archiveIds
      .map((item) => `${item.id}:${item.archiveType}`)
      .join(",");
    let response = await api.get(
      "/archives/getUserArchives?userArchivesId=" + idsString
    );
    const archives = response.data;
    if (archives.length > 0) {
      setRows(archives);
    }
  };

  useEffect(() => {
    getUserArchivesId();
  }, []);

  const handleTabChange = (event, newValue) => {
    if (
      tab === "documents" &&
      newValue !== "documents" &&
      pdfViewerRef.current !== null
    ) {
      // Leaving the documents tab, save the current page
      setLastPage(pdfViewerRef.current.getCurrentPage());
    }
    setTab(newValue);
  };

  const DraftAnswerPanel = styled(Paper)(({ theme }) => ({
    width: "calc(50% - 16px)", // Subtracting half of the spacing on each side
    minWidth: "calc(50% - 16px)",
    maxWidth: "calc(50% - 16px)",
    height: "100%",
    ...theme.typography.body2,
    overflow: "auto",
  }));

  const DocumentLeftPanel = styled(Paper)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "calc(20% - 16px)", // Subtracting half of the spacing
    minWidth: "calc(20% - 16px)",
    maxWidth: "calc(20% - 16px)",
    border: "1px solid #ddd",
    padding: theme.spacing(2),
    ...theme.typography.body2,
  }));

  const handleDocumentClick = async (doc) => {
    try {
      const matchingDoc = docs.find((d) => d.uri.includes(doc.id));
      setActiveDocument(matchingDoc);
    } catch (error) {
      console.error("Error fetching file content:", error);
    }
  };

  const DocumentLeftPanelComponent = ({ items, onItemClick }) => {
    return (
      <DocumentLeftPanel>
        <Typography
          variant="sectionHeading"
          style={{
            borderBottom: "1px solid #ddd",
            marginBottom: "7px",
            paddingBottom: "0.5rem",
            color: "black",
          }}
        >
          Files
        </Typography>
        <Box style={{ height: "100%", overflowY: "auto" }}>
          <List>
            {items.map((item, index) => (
              <ListItem
                button
                key={index}
                onClick={() => onItemClick(item)}
                sx={{
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    sx: {
                      fontSize: "0.875rem", // Sets the font size of the primary text
                      lineHeight: 1.2,
                      color: "#757575",
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                    },
                  }}
                  sx={{
                    borderRadius: "1.5rem",
                  }}
                  primary={item.name}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </DocumentLeftPanel>
    );
  };

  const DocumentViewerPanel = styled(Paper)(({ theme }) => ({
    width: "calc(80% - 8px)",
    minWidth: "calc(80% - 8px)",
    maxWidth: "calc(80% - 8px)",
    height: "100%",
    border: "1px solid #ddd",
    ...theme.typography.body2,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  }));

  const DocumentDisplayPanel = () => {
    if (
      activeDocument &&
      activeDocument.metadata.metadata.contentType === "application/pdf"
    ) {
      return (
        <DocumentViewerPanel>
          {tab === "documents" && (
            <PDFViewer
              ref={pdfViewerRef}
              fileUrl={activeDocument.uri}
              initialPage={lastPage}
            />
          )}
        </DocumentViewerPanel>
      );
    } else {
      return (
        <DocumentViewerPanel>
          <DocViewer
            documents={docs}
            activeDocument={activeDocument}
            pluginRenderers={DocViewerRenderers}
            prefetchMethod="GET"
            config={{
              header: {
                disableHeader: true,
              },
              pdfVerticalScrollByDefault: true,
              className: "custom-doc-viewer",
            }}
            style={{
              height: "100%",
              width: "100%",
              maxHeight: "100%",
              overflow: "auto",
            }}
          />
        </DocumentViewerPanel>
      );
    }
  };

  const handleItemClick = useCallback(
    (item) => {
      dispatch(
        setSelectedItem({
          uuid: item.uuid,
          section_title: item.section_title,
          answer: item.answer,
          sources: item.sources,
          requirements: item.requirements,
        })
      );
    },
    [dispatch]
  );

  const handleExport = async () => {
    if (!projectId) {
      alert("Please enter a Project ID");
      return;
    }

    try {
      const response = await api.post(
        "file/exportRFP",
        { projectId },
        {
          responseType: "arraybuffer",
        }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      // Create a link element and trigger the download
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "RFP_Document.docx";
      document.body.appendChild(link);
      link.click();

      // Clean up
      URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      console.error("Error downloading the document:", error);
      alert("Failed to download the document");
    }
  };

  const AntTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: "500",
    width: "8rem",
    minHeight: "3rem",
  }));

  const handleRFPDataUpdate = async (newData) => {
    setRfpData(newData);
    try {
      await api.put("/project/updateRFPChecklist", {
        projectId,
        rfx_checklist: newData,
      });
    } catch (error) {
      console.error("Error updating RFP Checklist:", error);
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.5rem 1rem",
          bgcolor: "background.paper",
          paddingLeft: "1rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", color: "#333" }}>
          <TextField
            value={editedProjectName}
            onChange={handleProjectNameChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleProjectNameSubmit();
                e.preventDefault();
                e.target.blur(); // Unfocus the input field
              }
            }}
            onBlur={handleProjectNameSubmit}
            autoFocus={projectNameEditing}
            inputProps={{ maxLength: 100 }}
            title="Rename"
            sx={{
              "& .MuiInputBase-input": {
                fontFamily: "Montserrat",
                fontWeight: 400,
                fontSize: "1.2rem",
                letterSpacing: "0.05em",
                width: "30rem",
                border: "none",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingTop: 0,
                paddingBottom: 0,
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 0,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #44686f",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "2px solid #719fa8",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            padding: "0rem 0.3rem 0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tabs
            value={tab}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="project tabs"
          >
            <AntTab
              value="overview"
              label="Overview"
              icon={<DashboardRoundedIcon />}
              iconPosition="start"
            />
            <AntTab
              value="draft"
              label="Draft"
              icon={<EditNoteRoundedIcon />}
              iconPosition="start"
            />
            <AntTab
              value="documents"
              label="Documents"
              icon={<FolderOpenRoundedIcon />}
              iconPosition="start"
            />
          </Tabs>
          <Button
            onClick={handleExport}
            variant="contained"
            startIcon={<IosShareRoundedIcon />}
            sx={{
              textTransform: "none",
              visibility: tab === "draft" ? "visible" : "hidden",
              marginRight: 2,
              height: "70%",
            }}
            disableRipple
          >
            Export
          </Button>
        </Box>

        <Box sx={{ flexGrow: 1, overflow: "auto", padding: "0.5rem" }}>
          {tab === "overview" &&
            (isCheckListLoading ? (
              <RFPChecklistSkeleton />
            ) : (
              <RFPChecklist
                RFPData={rfpCheckListData}
                onRFPDataUpdate={handleRFPDataUpdate}
              />
            ))}

          {tab === "draft" && (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                height: "100%",
                width: "100%",
                justifyContent: "space-between",
                padding: "0rem 0.5rem",
              }}
            >
              <DocumentLeftPanel>
                <DraftLeftPanelComponent
                  onItemClick={handleItemClick}
                  projectId={projectId}
                  archiveIds={userArchiveIDs.map((item) => item.id)}
                  refreshItems={fetchProjectData}
                />
              </DocumentLeftPanel>

              <DraftAnswerPanel>
                <TipTapEditor
                  rteRef={rteRef}
                  projectId={projectId}
                  archiveIds={userArchiveIDs.map((item) => item.id)}
                  refreshItems={fetchProjectData}
                  showToolbar={true}
                  displayItem="answer"
                />
              </DraftAnswerPanel>

              <DraftSourcesPanelComponent
                projectId={projectId}
                userArchiveIDs={userArchiveIDs.map((item) => item.id)}
                refreshItems={fetchProjectData}
                rowData={rowData}
              />
            </Stack>
          )}
          {tab === "documents" && (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                height: "100%",
                width: "100%",
                justifyContent: "space-between",
                padding: "0rem 0.5rem",
              }}
            >
              <DocumentLeftPanelComponent
                items={projectFiles}
                onItemClick={handleDocumentClick}
              />
              <DocumentDisplayPanel />
            </Stack>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default RFPEditor;
