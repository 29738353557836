import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assignments: {}, // { sectionId: { responder: userId, reviewer: userId, approver: userId } }
};

export const userAssignmentSlice = createSlice({
  name: "userAssignment",
  initialState,
  reducers: {
    assignUser: (state, action) => {
      const { sectionId, role, userId } = action.payload;
      if (!state.assignments[sectionId]) {
        state.assignments[sectionId] = {};
      }
      state.assignments[sectionId][role] = userId;
    },
    removeAssignment: (state, action) => {
      const { sectionId, role } = action.payload;
      if (state.assignments[sectionId]) {
        delete state.assignments[sectionId][role];
        if (Object.keys(state.assignments[sectionId]).length === 0) {
          delete state.assignments[sectionId];
        }
      }
    },
    clearSectionAssignments: (state, action) => {
      const { sectionId } = action.payload;
      delete state.assignments[sectionId];
    },
  },
});

export const { assignUser, removeAssignment, clearSectionAssignments } =
  userAssignmentSlice.actions;

// Selectors
export const selectSectionAssignments = (state, sectionId) =>
  state.userAssignment.assignments[sectionId] || {};
export const selectAllAssignments = (state) => state.userAssignment.assignments;

export default userAssignmentSlice.reducer;
