import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Typography,
  Box,
  Popover,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  AvatarGroup,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  assignUser,
  removeAssignment,
  selectSectionAssignments,
} from "../../reduxstore/userAssignmentSlice";
import Person2RoundedIcon from "@mui/icons-material/Person2Rounded";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import ClearIcon from "@mui/icons-material/Clear";
import EmailIcon from "@mui/icons-material/Email";

const roles = ["Author", "Reviewer", "Approver"];

const UserAssignment = ({ sectionId, users }) => {
  const dispatch = useDispatch();
  const assignments = useSelector((state) =>
    selectSectionAssignments(state, sectionId)
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRole, setActiveRole] = useState(null);
  const [selectedUser, setSelectedUser] = useState("");
  const [showRoles, setShowRoles] = useState(false);

  const handleMainClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowRoles(true);
    setActiveRole(null);
  };

  const handleRoleClick = (role) => {
    setActiveRole(role);
    setShowRoles(false);
    setSelectedUser("");
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveRole(null);
    setSelectedUser("");
    setShowRoles(false);
  };

  const handleAssign = () => {
    dispatch(assignUser({ sectionId, role: activeRole, userId: selectedUser }));
    handleClose();
  };

  const handleRemove = (role) => {
    dispatch(removeAssignment({ sectionId, role }));
  };

  const handleInvite = () => {
    // Placeholder for invite functionality
    console.log(`Inviting user for role: ${activeRole}`);
    // Here you would typically open a modal or navigate to an invite page
    // For now, we'll just close the popover
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-assignment-popover" : undefined;

  const getAvailableUsers = (role) => {
    return users.filter(
      (user) =>
        !Object.entries(assignments).some(
          ([assignedRole, assignedUserId]) =>
            assignedRole !== role && assignedUserId === user.id
        )
    );
  };

  const getInitials = (name) => {
    if (name === "" || name === undefined) return;
    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  const assignedUsers = roles
    .map((role) => {
      const userId = assignments[role.toLowerCase()];
      const user = users.find((u) => u.id === userId);
      return user ? { ...user, role } : null;
    })
    .filter(Boolean);

  const allRolesAssigned = assignedUsers.length === roles.length;

  return (
    <Box>
      <AvatarGroup
        max={3}
        spacing="10"
        onClick={handleMainClick}
        sx={{ cursor: "pointer" }}
      >
        {assignedUsers.map((user, index) => (
          <Tooltip key={user.id} title={`${user.name} (${user.role})`} arrow>
            <Avatar
              sx={{
                width: 20,
                height: 20,
                fontSize: "0.7rem",
                bgcolor: `hsl(${index * 70}, 50%, 50%)`,
              }}
            >
              {getInitials(user.name)}
            </Avatar>
          </Tooltip>
        ))}
        {!allRolesAssigned && (
          <Avatar
            sx={{
              width: 20,
              height: 20,
            }}
          >
            <PersonAddAltRoundedIcon sx={{ fontSize: "1rem" }} />
          </Avatar>
        )}
      </AvatarGroup>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2, width: 300 }}>
          {showRoles ? (
            <>
              <Typography
                variant="sectionHeading"
                sx={{ marginRight: "8px", color: "black" }}
              >
                Users
              </Typography>
              <List>
                {roles.map((role) => (
                  <ListItem
                    key={role}
                    button
                    onClick={() => handleRoleClick(role.toLowerCase())}
                  >
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          bgcolor: "#0000008a",
                          width: 25,
                          height: 25,
                          fontSize: "0.875rem",
                        }}
                      >
                        <Person2RoundedIcon sx={{ fontSize: "1rem" }} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={role}
                      secondary={
                        assignments[role.toLowerCase()]
                          ? users.find(
                              (u) => u.id === assignments[role.toLowerCase()]
                            )?.name || "Unknown User"
                          : "Not assigned"
                      }
                      primaryTypographyProps={{
                        sx: {
                          fontSize: "0.875rem",
                          lineHeight: 1.2,
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                        },
                      }}
                      secondaryTypographyProps={{
                        sx: {
                          fontSize: "0.875rem",
                          lineHeight: 1.2,
                          color: "#757575",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                        },
                      }}
                    />
                    {assignments[role.toLowerCase()] && (
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="clear"
                          onClick={() => handleRemove(role.toLowerCase())}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
              </List>
            </>
          ) : (
            activeRole && (
              <>
                <Typography
                  variant="sectionHeading"
                  sx={{ marginRight: "8px", color: "black" }}
                >
                  Assign {activeRole}
                </Typography>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="user-select-label">Select User</InputLabel>
                  <Select
                    labelId="user-select-label"
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e.target.value)}
                    label="Select User"
                  >
                    {getAvailableUsers(activeRole).map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleAssign}
                  disabled={!selectedUser}
                  sx={{ mt: 2 }}
                >
                  Assign User
                </Button>
                <Divider sx={{ my: 2 }}>or</Divider>
                <Button
                  fullWidth
                  variant="outlined"
                  startIcon={<EmailIcon />}
                  onClick={handleInvite}
                  sx={{ mt: 2 }}
                >
                  Invite User
                </Button>
              </>
            )
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default UserAssignment;
