import { configureStore } from "@reduxjs/toolkit";
import itemsReducer from "./itemsSlice";
import selectedItemReducer from "./selectedItemSlice";
import itemsStatusReducer from "./itemsStatusSlice";
import userAssignmentReducer from "./userAssignmentSlice";

export const store = configureStore({
  reducer: {
    items: itemsReducer,
    selectedItem: selectedItemReducer,
    itemsStatus: itemsStatusReducer,
    userAssignment: userAssignmentReducer,
  },
});
