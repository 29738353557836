import React, { useState } from "react";
import {
  Typography,
  Box,
  Popover,
  Tooltip,
  IconButton,
  Divider,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const RecordAnswer = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMainClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUploadAudio = () => {
    console.log("Upload audio triggered");
    handleClose();
  };

  const handleRecordAudio = () => {
    console.log("Record audio triggered");
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-assignment-popover" : undefined;

  return (
    <Box>
      <Tooltip title="Record Answer">
        <IconButton onClick={handleMainClick}>
          <MicIcon sx={{ fontSize: "1.25rem" }} />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: 230,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton onClick={handleRecordAudio} size="large">
              <FiberManualRecordIcon color="error" />
            </IconButton>
            <Typography variant="caption">Record Answer</Typography>
          </Box>

          <Divider orientation="vertical" flexItem />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton onClick={handleUploadAudio} size="large">
              <CloudUploadIcon color="primary" />
            </IconButton>
            <Typography variant="caption">Upload Audio</Typography>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default RecordAnswer;
